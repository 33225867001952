export enum CallControlStateChangeEventEnum {
    IncomingCallInitiated,
    IncomingCallEnded,
    AcceptCallInitiated,
    AcceptCallCompleted,
    CallConnected,
    AcceptCallError,
    RejectCallInitiated,
    RejectCallError,
    EndCallInitiated,
    EndCallCompleted,
    EndCallError,
    HoldCallInitiated,
    HoldCallCompleted,
    HoldCallError,
    ResumeCallInitiated,
    ResumeCallCompleted,
    ResumeCallError,
    ToggleMuteError,
    BlindTransferInitiated,
    StartOutboundCallInitiated,
    StartOutboundCallCompleted,
    OutboundCallConnected
}