import { ICompletedTranscript, IInteraction, ITranscript, LOG_LEVEL } from '@amc-technology/davinci-api';

import React from 'react';

interface props {
    logger: any;
}

export class StorageService extends React.Component<props> {
    logger: any;
    public interactionList: {
        [scenarioId: string]: IInteraction;
    };
    public transcriptList: {
        [interactionId: string]: ITranscript[];
    };

    private readonly className = 'StorageService';
    constructor(props:any) {
        super(props);
        this.logger = props.logger;
        this.interactionList = {};
        this.transcriptList = {};
    }

     /**
   * Add interaction to interactionList
   * @param {IInteraction} interaction - key to be mapped to the interaction
   * @memberof StorageService
   */
    public addInteraction(interaction: IInteraction) {
        const functionName = `addInteraction`;
        try {
            this.logger.log(LOG_LEVEL.Trace, functionName, `StorageService : START`);
            if (!this.interactionList) {
                this.interactionList = {};
            }
            if (interaction.scenarioId !== undefined) {
                this.interactionList[interaction.scenarioId] = interaction;
            }
            this.storeToLocalStorage();
        } catch (error) {
            this.logger.log(LOG_LEVEL.Error,functionName, ` : StorageService : ERROR`, error);
        }
      }

    /**
   * Remove interaction from interactionList
   * @param {string} scenarioId - key mapped to the interaction
   * @memberof StorageService
   */
    public removeInteraction(scenarioId: string) {
        const functionName = `removeInteraction`;
        try {
            this.logger.log(LOG_LEVEL.Trace, functionName, `StorageService : START`);
            if (this.interactionList[scenarioId]) {
                delete this.interactionList[scenarioId];
            }
            this.storeToLocalStorage();
        } catch (error) {
            this.logger.log(LOG_LEVEL.Error,functionName, ` : StorageService : ERROR`, error);
        }
    }

    /**
   * Add a transcript for a specified interaction to the transcriptList
   * @param {string} interactionId - key mapped to the list of transcripts.
   * @memberof StorageService
   */
    public async addTranscript(interactionId: string, transcript: ITranscript) {
        const functionName = `addTranscript`;

        try {
            this.logger.log(LOG_LEVEL.Trace, functionName, `StorageService : START`);
            if (!this.transcriptList) {
                this.transcriptList = {};
            }
            if (this.transcriptList[interactionId] === undefined) {
                this.transcriptList[interactionId] = [];
            }
            this.transcriptList[interactionId].push(transcript);
            this.storeTranscriptToLocalStorage();
        } catch (error) {
            this.logger.log(LOG_LEVEL.Error,functionName, ` : StorageService : ERROR`, error);
        }
    }

    /**
   * Removes all transcripts for a specified interaction from the transcriptList and returns the completed transcript object.
   * @param {string} interactionId - key mapped to the list of transcripts.
   * @memberof StorageService
   */
    public removeAllTranscripts(interactionId: string): ICompletedTranscript {
        const functionName = `removeAllTranscripts`;

        try {
            this.logger.log(LOG_LEVEL.Trace, functionName, `StorageService : START`);
            const date = new Date();
            const completedTranscript: ICompletedTranscript = {
                id: date.getTime().toString(),
                messages: this.transcriptList[interactionId],
                analytics: [],
                startTimestamp: this.transcriptList[interactionId][0].timestamp,
                endTimestamp: this.transcriptList[interactionId][this.transcriptList[interactionId].length - 1].timestamp
            };

            if (this.transcriptList[interactionId]) {
                delete this.transcriptList[interactionId];
            }
            this.storeTranscriptToLocalStorage();

            return completedTranscript;
        } catch (error) {
            this.logger.log(LOG_LEVEL.Error,functionName, ` : StorageService : ERROR: ${error}`);
        }
    }

    /**
   * Retrieve data from local storage and sync
   *
   * @memberof StorageService
   */
    public syncWithLocalStorage() {
        const functionName = `syncWithLocalStorage`;

        try {
            this.logger.log(LOG_LEVEL.Trace, functionName, `StorageService : START`);
            
            const interactionRecord = localStorage.getItem('interaction');
            const transcriptRecord = localStorage.getItem('transcript');
            if(interactionRecord) {
                const browserStorage = JSON.parse(interactionRecord);
                this.interactionList = browserStorage.interactionList;
            }
            if(transcriptRecord) {
                const browserStorage = JSON.parse(transcriptRecord);
                this.transcriptList = browserStorage.transcriptList;
            }
        } catch (error) {
            this.logger.log(LOG_LEVEL.Error,functionName, ` : StorageService : ERROR`, error);
        }
    }

    /**
   * Stores data in local storage
   *
   * @memberof StorageService
   */
    public storeToLocalStorage() {
        const functionName = `storeToLocalStorage`;
        try {
            this.logger.log(LOG_LEVEL.Trace, functionName, `StorageService : START`);
            const prevInteractionRecord = localStorage.getItem('interaction');
            const interactionRecord = JSON.stringify({
                interactionList: this.interactionList
            });
            this.logger.log(LOG_LEVEL.Trace, functionName, `: Storing to Local Storage. Scenario before update :`, prevInteractionRecord);
            localStorage.setItem('interaction', interactionRecord);
            this.logger.log(LOG_LEVEL.Trace, functionName, ` DONE : Storing to Local Storage. Scenario after update :`, interactionRecord);
        } catch (error) {
            this.logger.log(LOG_LEVEL.Error, functionName, ` : StorageService : ERROR`, error);
        }
    }

    /**
   * Stores transcript data in local storage.
   *
   * @memberof StorageService
   */
    public storeTranscriptToLocalStorage() {
        const functionName = `storeTranscriptToLocalStorage`;

        try {
            this.logger.log(LOG_LEVEL.Trace, functionName, `StorageService : START`);
            const prevTranscriptRecord = localStorage.getItem('transcript');
            const transcriptRecord = JSON.stringify({   
                transcriptList: this.transcriptList
            });
            this.logger.log(LOG_LEVEL.Trace, functionName, `: Storing to Local Storage. Transcript list before update :`, prevTranscriptRecord);
            localStorage.setItem('transcript', transcriptRecord);
            this.logger.log(LOG_LEVEL.Trace, functionName, ` DONE : Storing to Local Storage. Transcript list after update :`, transcriptRecord);
        } catch (error) {
            this.logger.log(LOG_LEVEL.Error, functionName, ` : StorageService : ERROR`, error);
        }
    }
    


}
