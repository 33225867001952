import {
  AudioConfig,
  SpeechConfig,
  SpeechRecognizer
} from 'microsoft-cognitiveservices-speech-sdk';
import {
  CHANNEL_TYPES,
  CONTEXTUAL_OPERATION_TYPE,
  IContextualContact,
  IInteraction,
  INTERACTION_DIRECTION_TYPES,
  INTERACTION_STATES,
  ITranscript,
  ICompletedTranscript,
  IUserDetails,
  LOG_LEVEL,
  RecordItem,
  USER_TYPES,
  contextualOperation,
  registerClickToDial,
  registerContextualControls,
  sendNotification,
  setInteraction
} from "@amc-technology/davinci-api";
import {
  Call,
  CallCommon,
  CallKind,
  DtmfTone,
  Features,
  HangUpOptions,
  IncomingCall,
  IncomingCallCommon,
  TeamsCall,
  TeamsCallAgent,
  TeamsCaptions,
  TeamsCaptionsInfo,
  TeamsIncomingCall,
  Transfer,
  TransferCallFeature,
  TransferToParticipantLocator,
  TransferToParticipantOptions
} from '@azure/communication-calling';
import {
  CallTransfer20Filled,
  Dialpad20Filled,
  Pause20Filled,
  Pause20Regular,
} from '@fluentui/react-icons';
import {
  ControlBar,
  ControlBarButton,
  DEFAULT_COMPONENT_ICONS,
  EndCallButton,
  FluentThemeProvider,
  MicrophoneButton,
  VideoTile
} from '@azure/communication-react';
import {
  initializeIcons,
  registerIcons
} from '@fluentui/react';
import ClickToActComponent from './ClickToActComponent';
import { DTMFdigits } from './Models/DTMFevents.enum';
import { IncomingCallToast } from './IncomingCallComponent';
import React from 'react';
import { StorageService } from './StorageService';
import { bind } from 'bind-decorator';
import { getDisplayName } from './GraphService';
import { CallControlButtonStates } from './Models/CallControlsState';
import { CallControlStateChangeEventEnum } from './Models/CallControlStateChangeEvent.enum';

// The following link details the error codes that can be returned by the Teams SDK and what those errors mean.
// https://learn.microsoft.com/en-us/azure/communication-services/resources/troubleshooting/voice-video-calling/troubleshooting-codes?pivots=calling

initializeIcons();
registerIcons({ icons: DEFAULT_COMPONENT_ICONS });

const TEAMS_DIRECTION_MAP = {
  'Incoming' : INTERACTION_DIRECTION_TYPES.Inbound,
  'Outgoing' : INTERACTION_DIRECTION_TYPES.Outbound
}

const DTMF_MAP: {[key: string]: DTMFdigits} = {
  '0': DTMFdigits.ZERO,
  '1': DTMFdigits.ONE,
  '2': DTMFdigits.TWO,
  '3': DTMFdigits.THREE,
  '4': DTMFdigits.FOUR,
  '5': DTMFdigits.FIVE,
  '6': DTMFdigits.SIX,
  '7': DTMFdigits.SEVEN,
  '8': DTMFdigits.EIGHT,
  '9': DTMFdigits.NINE,
  '*': DTMFdigits.STAR,
  '#': DTMFdigits.POUND,
};

interface props {
  callAgent: any;
  setTeamsPresence: any;
  logger: any;
  transcriptionConfig: any;
  cognitiveServicesConfig: any;
  minAppHeight: number;
  authProvider: any;
  agentDetails: IUserDetails;
  id: string;
  muteEnabled: boolean;
}

interface state {
    isOnCall: boolean;
    isCallOnHold: boolean;
    incomingCallAlert: boolean;
    audioButtonChecked: boolean;
    dialpadInputValue: string;
    currentCallId: string;
    showDialPad: boolean;
    currentCallState: INTERACTION_STATES | undefined;
    callControlButtonStates: CallControlButtonStates;
    incomingCallList: {
      [key: string]: any;
    };
    callList: {
      [key: string]: any;
    };
    interactionList: {
      [scenarioId: string]: IInteraction;
    };
}
export class CallComponent extends React.Component<props, state> {
  stackTokens: any;
  dialStyle: any;
  dialPadBtn: any;
  controlBarStyles: any;
  videoTileStyles: any;
  displayName: string;
  callAgent: TeamsCallAgent;
  transferAgent: Transfer | undefined;
  storageService: any;
  clickToAct: any;
  transcriptionConfig: any;
  remoteAudioStarted: boolean;
  minAppHeight: number;
  agentAttributes: any;
  agentPhoneNumber: string;
  callCaptionsList: {
    [key: string]: TeamsCaptions;
  };
  blindTransferInitiated: boolean;

  constructor(props: any) {
    const functionName = 'constructor';
    super(props);
    try {
      this.minAppHeight = props.minAppHeight ?? 5;
      this.setAgentNumber();
      this.displayName = '';
      this.transferAgent = undefined;
      this.remoteAudioStarted = false;
      // TODO: This needs to be moved up to the index.tsx file and passed as props since these are just helper functions
      this.storageService = new StorageService(props);
      // TODO: This needs to be moved up to the index.tsx file and passed as props since these are just helper functions
      this.clickToAct = new ClickToActComponent({
        logger:this.props.logger,
        acceptCall:this.acceptCall,
        blindTransfer: this.blindTransfer,
        rejectCall: this.rejectCall,
        hangupCall: this.endCall,
        holdCall: this.holdCall,
        resumeCall: this.resumeCall,
        setTeamsPresence: this.props.setTeamsPresence,
        unmuteCall: this.unmuteCall,
        muteCall: this.muteCall,
        handleDTMF: this.handleDTMF
      });
      this.clickToAct.initApi();
      this.state = {
        isOnCall: false,
        isCallOnHold: false,
        incomingCallAlert: false,
        audioButtonChecked: true,
        currentCallState: undefined,
        callControlButtonStates: {
          acceptCallButtonDisabled: false,
          rejectCallButtonDisabled: false,
          endCallButtonDisabled: false,
          muteCallButtonDisabled: false,
          holdCallButtonDisabled: false,
          resumeCallButtonDisabled: false,
          blindTransferButtonDisabled: false,
        },
        dialpadInputValue: '',
        incomingCallList:{},
        callList:{},
        currentCallId:'',
        showDialPad: false,
        interactionList: {}
      };
      this.callCaptionsList = {};
      this.stackTokens = { childrenGap: 40 };
      this.blindTransferInitiated = false;
      this.dialPadBtn = {backgroundColor: '#fff' };
      this.dialStyle = { root: { margin: 0, border: 'solid .5px', paddingBottom: '36px', paddingTop:'1.1rem', paddingRight: '1.1rem', paddingLeft: '1.1rem', maxWidth: 'inherit', minWidth: '121%' } };
      this.controlBarStyles = { root: {border: '0.5px solid', borderRadius: '3px', width: 'fit-content', maxWidth: '100%', marginLeft: 'auto', marginRight: 'auto'} };
      this.videoTileStyles = {
        root: { minHeight: '230px', width: '100%', border: '1px solid #999', marginLeft: 'auto'},
        displayNameContainer: {marginLeft: 'auto', marginRight: 'auto'}
      };
      if (props.callAgent) {
          props.callAgent?.on('incomingCall', (args: any) => {
              this.incomingCallHandler(args);
          });
      }
      registerClickToDial(this.clickToDial);
      registerContextualControls(this.startCall);
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
    }
  }

  /**
   * This function is called when the component is mounted. We utilize this method to setup the callback to perform cleanup upon receiving a beforeunload event.
   * @memberof CallComponent
   */
  componentDidMount() {
    const functionName = 'componentDidMount';

    try {
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START`);
      
      window.addEventListener('beforeunload', this.handleBeforeUnload);

      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
    }
  }

  private setAgentNumber() {
    const functionName = 'setAgentNumber';

    try {
      this.agentAttributes = this.props.agentDetails?.attributes !== "" ? JSON.parse(this.props.agentDetails?.attributes) : [];
        for (let i = 0; i < this.agentAttributes.length; i++) {
          if (this.agentAttributes[i].name === 'Phone Number') {
            this.agentPhoneNumber = this.agentAttributes[i].value;
          }
        }
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
    }
  }

   /**
   * Starts a call initiated by click to dial
   * @param {stringy} phoneNumber - phone number to be called
   * @memberof CallComponent
   */
  @bind
  async clickToDial(phoneNumber: string) {
    const functionName = 'clickToDial';

    try {
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START`);

      let numberDialed: IContextualContact = {
        channels: [],
        displayName: phoneNumber,
        uniqueId: phoneNumber
      };
      this.startCall(numberDialed);

      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
    }
  }

  /**
   * Receives incoming call from Microsoft Teams and handles it
   * @param {IncomingCall} incomingCall - incoming call object
   * @memberof CallComponent
   */
  @bind
  incomingCallHandler(args: { incomingCall: IncomingCall | TeamsIncomingCall }) {
    const functionName = 'incomingCallHandler';

    try {
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START`);
      const currentCallId = args.incomingCall.id; 
      if(!this.state.incomingCallList.hasOwnProperty(args.incomingCall.id)) {
        const incomingCallList = this.state.incomingCallList;
        incomingCallList[args.incomingCall.id] = args.incomingCall;
        this.displayName = incomingCallList[args.incomingCall.id].callerInfo.displayName? incomingCallList[args.incomingCall.id].callerInfo.displayName : '';
        this.handleCallControlStateChange(CallControlStateChangeEventEnum.IncomingCallInitiated, incomingCallList, null, currentCallId);
        this.prepareAndSetInteraction(incomingCallList[currentCallId]);
        // Subscribe to callEnded event and get the call end reason
        (incomingCallList[args.incomingCall.id] as IncomingCallCommon).on('callEnded', (args: any) => {
            this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : Incoming Call Ended: ${this.state.incomingCallList[this.state.currentCallId]}`);
            this.handleCallControlStateChange(CallControlStateChangeEventEnum.IncomingCallEnded);
            this.prepareAndSetInteraction(incomingCallList[currentCallId]);
        });
      }

      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
    }
  }

  /**
   * Start outbound call
   * @param {string} number - optional parameter - number to call
   * @memberof CallComponent
   */
  @bind
  async startCall(contact: IContextualContact) {
    const functionName = 'startCall';

    try {
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START`);
      const callList = this.state.callList;
      let callTarget;
      this.displayName = contact.uniqueId;
      const phoneNumber = contact.uniqueId;
      if (contact?.channels?.length === 0) {
        callTarget = {phoneNumber: phoneNumber};
      } else {
        callTarget = {microsoftTeamsUserId: phoneNumber};
      }
      if (this.props.callAgent) {
        const outboundCall = await this.props.callAgent.startCall(callTarget);
        const currentCallId = outboundCall.id;
        callList[currentCallId] = outboundCall;
        const callObject = {
          phoneNumber: phoneNumber,
          kind: 'phoneNumber',
          id: outboundCall.id,
        };
        callList[currentCallId].callObject = callObject;
        this.handleCallControlStateChange(CallControlStateChangeEventEnum.StartOutboundCallInitiated, null, callList, currentCallId, phoneNumber);
        outboundCall.on('stateChanged', this.handleActiveCallStateChanges);
      }

      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
    }
  }

   /**
   * Create a CAD object
   * @param {any} rawCADData - call information data
   * @memberof CallComponent
   */
  constructCADObject(rawCADData: any) {
    const functionName = 'constructCADObject';

    try {
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START`);
      if (rawCADData) {
        const cadObject: any = {};
        for (const cad in rawCADData) {
          if (rawCADData.hasOwnProperty(cad)) {
            cadObject[cad] = {
              DevName: '',
              DisplayName: '',
              Value: rawCADData[cad],
            };
          }
        }
        this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
        return cadObject;
      } else {
        this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
        return undefined;
      }
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
    }
  }

  /**
   * Accept inbound call
   * @memberof CallComponent
   */
  @bind
  async acceptCall() {
    const functionName = 'acceptCall';

    try {
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START`);
      
      if (this.state.callControlButtonStates.acceptCallButtonDisabled === false && this.state.callControlButtonStates.rejectCallButtonDisabled === false) {
        this.handleCallControlStateChange(CallControlStateChangeEventEnum.AcceptCallInitiated);
        const callList = this.state.callList;
        callList[this.state.currentCallId] = await (this.state.incomingCallList[this.state.currentCallId] as IncomingCall | TeamsIncomingCall).accept();
        this.handleCallControlStateChange(CallControlStateChangeEventEnum.AcceptCallCompleted, null, callList);
        (callList[this.state.currentCallId] as Call | TeamsCall).on('stateChanged', this.handleActiveCallStateChanges);
      }

      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);   
      if (error?.code === 400 && error.subCode === 41006) {
        this.handleCallControlStateChange(CallControlStateChangeEventEnum.AcceptCallError);
      }
    }
  }

  @bind
  async blindTransfer() {
    const functionName = 'blindTransfer';

    try {
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START`);

      const callList = this.state.callList;
      if (this.blindTransferInitiated === false) { 
        let transferFeature = callList[this.state.currentCallId].feature(Features.Transfer) as TransferCallFeature;
        this.blindTransferInitiated = true;
        const response = await contextualOperation(
          CONTEXTUAL_OPERATION_TYPE.BlindTransfer,
          CHANNEL_TYPES.Telephony
        )
        .catch((error) => {
          this.props.logger.log(
            LOG_LEVEL.Error,
            functionName,
            `TEAMS - CallComponent - contextualOperation - Error: ${error.message || error
            }`
          );
          this.blindTransferInitiated = false;
        });

        let teamsId = '';
        if (response) {
          for (const channel in response.channels) {
            if (response.channels[channel].idName === 'Microsoft Teams User Id') {
              teamsId = response.channels[channel].id;
            }
          }
          if (teamsId !== '') {
            let id = {microsoftTeamsUserId: teamsId};
            const target: TransferToParticipantLocator = {
              targetParticipant: id
            }
            const options: TransferToParticipantOptions = { disableForwardingAndUnanswered : false};
            this.transferAgent = transferFeature.transfer(target, options) as Transfer;
            this.transferAgent.on('stateChanged', () => {
              try {
                if (this.transferAgent.state === 'Transferred') {
                  this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : Blind Transfer was successful`);
                  callList[this.state.currentCallId].hangUp();
                } else if (this.transferAgent.state === 'Failed') {
                  this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : Blind Transfer failed so the call will be ended`);
                  callList[this.state.currentCallId].hangUp();
                } else {
                  this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : Blind Transfer is in progress`);
                }
              } catch(error) {
                this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
              } 
            });
          } else {
            this.props.logger.log(LOG_LEVEL.Debug, functionName, `CallComponent : User not associated to a teams account`);
            sendNotification('User attempting to blind Transfer to is not a Microsoft Teams user');
          }
        }
      } else {
        this.props.logger.log(LOG_LEVEL.Debug, functionName, `CallComponent : Blind Transfer already initiated`);
      }

      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
    }
  }

  /**
   * Reject inbound call
   *
   * @memberof CallComponent
   */
  @bind
  async rejectCall() {
    const functionName = 'rejectCall';

    try {
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START`);

      if (this.state.callControlButtonStates.rejectCallButtonDisabled === false && this.state.callControlButtonStates.acceptCallButtonDisabled === false) {
        this.handleCallControlStateChange(CallControlStateChangeEventEnum.RejectCallInitiated);
        (this.state.incomingCallList[this.state.currentCallId] as IncomingCall | TeamsIncomingCall).reject();
      }

      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
      if (error?.code === 400 && error.subCode === 41007) {
        this.handleCallControlStateChange(CallControlStateChangeEventEnum.RejectCallError);
      }
    }
  }

  /**
   * End call
   *
   * @memberof CallComponent
   */
  @bind
  async endCall() {
    const functionName = 'endCall';

    try {
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START`);

      this.handleCallControlStateChange(CallControlStateChangeEventEnum.EndCallInitiated);    
      const call: Call | TeamsCall = await this.state.callList[this.state.currentCallId];
      call.hangUp({
        forEveryone: true
      } as HangUpOptions);

      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR: ${error}`);
      this.handleCallControlStateChange(CallControlStateChangeEventEnum.EndCallError);
    }
  }

  /**
   * Place the current call on hold
   *
   * @memberof CallComponent
   */
  @bind
  async holdCall() {
    const functionName = `holdCall`;

    try {
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `Holding Call the current call.`);

      this.handleCallControlStateChange(CallControlStateChangeEventEnum.HoldCallInitiated);
      const call: Call | TeamsCall = await this.state.callList[this.state.currentCallId];
      await call?.hold();

      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
    } catch (error) {
      if (error?.code === 500 && error?.subCode === 41035) {
        this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR - Hold Call error was returned by teams SDK error code: ${error.code} and error subcode: ${error.subCode}`);
      } else {
        this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
      }
      this.handleCallControlStateChange(CallControlStateChangeEventEnum.HoldCallError);
    }
  }

  /**
   * Unhold the current call
   *
   * @memberof CallComponent
   */
  @bind
  async resumeCall() {
    const functionName = `resumeCall`;

    try {
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START`);

      this.handleCallControlStateChange(CallControlStateChangeEventEnum.ResumeCallInitiated);
      const call: Call | TeamsCall = await this.state.callList[this.state.currentCallId];
      await call?.resume();
      if (this.blindTransferInitiated === true) {   
        await contextualOperation(
          CONTEXTUAL_OPERATION_TYPE.Cancel,
          CHANNEL_TYPES.Telephony
        )
        .catch((error) => {
          this.props.logger.log(
            LOG_LEVEL.Error,
            functionName,
            `TEAMS - CallComponent - contextualOperation - Error: ${error.message || error
            }`
          );
        });
        this.blindTransferInitiated = false;
      }

      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
    } catch (error) {
      if (error?.code === 500 && error?.subCode === 41034) {
        this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR - Resume Call error was returned by teams SDK error code: ${error.code} and error subcode: ${error.subCode}`);
      } else {
        this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
      }
      this.handleCallControlStateChange(CallControlStateChangeEventEnum.ResumeCallError);
    }   
  }

  /**
   * Mute the current call
   *
   * @memberof CallComponent
   */
  @bind
  async muteCall() {
    const functionName = `muteCall`;

    try {
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START`);

      const call: Call | TeamsCall = await this.state.callList[this.state.currentCallId];
      await call?.mute();

      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
    } catch (error) {
      if (error?.code === 500 && error?.subCode === 41015) {
        this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR - Mute Call error was returned by teams SDK error code: ${error.code} and error subcode: ${error.subCode}`);
      } else {
        this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
      }
      this.handleCallControlStateChange(CallControlStateChangeEventEnum.ToggleMuteError);
    }
  }

  /**
     * Unmute the current call
     *
     * @memberof CallComponent
     */
  @bind
  async unmuteCall() {
    const functionName = `unmuteCall`;

    try {
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START`);

      const call: Call | TeamsCall = await this.state.callList[this.state.currentCallId];
      await call?.unmute();

      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
    } catch (error) {
      if (error?.code === 500 && error?.subCode === 41016) {
        this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR - Unmute Call error was returned by teams SDK error code: ${error.code} and error subcode: ${error.subCode}`);
      } else {
        this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
      }
      this.handleCallControlStateChange(CallControlStateChangeEventEnum.ToggleMuteError);
    }
  }

  /**
     * Send DTMF tones
     * @param {string} digits - digit to pressed
     * @memberof CallComponent
     */
  @bind
  async handleDTMF(digits: string) {
    const functionName = `handleDTMF`;

    try {
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START`);
      const call: Call | TeamsCall = await this.state.callList[this.state.currentCallId];
      if(call && call.state === 'Connected') {
        call.sendDtmf(digits as DtmfTone);
        this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
      }
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR: + ${error}`);
    }
  }

  /**
     * Open DTMF input for agents within the keypad
     * @memberof CallComponent
     */
  @bind
  async openDTMF() {
    const functionName = `openDTMF`;

    try {
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START`);


      let div = document.getElementById('greyLayer')?.style;
      div!.display = 'block';

      await contextualOperation(
        CONTEXTUAL_OPERATION_TYPE.DTMF,
        CHANNEL_TYPES.Telephony, async (contact: IContextualContact) => {
          await this.handleDTMF(DTMF_MAP[contact.uniqueId]);
        }
      ).then(async (contact) => {})
      .catch((error) => {
        this.props.logger.log(
          LOG_LEVEL.Error,
          functionName,
          `TEAMS - CallComponent - contextualOperation - Error: ${error.message || error
          }`
        );
      });
      div!.display = 'none';

      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR: + ${error}`);
    }
  }

  /**
   * This function is a callback method that will handle making required UI updates and sending updated interaction events whenever 'stateChanged' events are received for an active call.
   * Note that we do not implement logic to handle all possible call stateChanged events, only the ones that were received during initial development/testing.
   * @memberof CallComponent
   */
  @bind
  async handleActiveCallStateChanges() {
    const functionName = 'handleActiveCallStateChanges';

    try {
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START`);

      const call: Call | TeamsCall = await this.state.callList[this.state.currentCallId];
      
      switch (call.state) {
        case 'None':
          this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : Received a None state change event, but this state is not handled in the current implementation.`);
          break;
        case 'Connecting':
          this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : Received a Connecting state change event, but this state change does not effect the UI or state of the Teams application.`);       
          break;
        case 'Ringing':
          this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : Received a Ringing state change event.`);
          this.handleCallControlStateChange(CallControlStateChangeEventEnum.StartOutboundCallCompleted); 
          this.prepareAndSetInteraction(this.state.callList[this.state.currentCallId]);
          break;
        case 'Connected':
          this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : Received a Connected state change event.`);
          if (this.state.currentCallState === INTERACTION_STATES.OnHold) {
            this.handleCallControlStateChange(CallControlStateChangeEventEnum.ResumeCallCompleted);
            this.prepareAndSetInteraction(call, CHANNEL_TYPES.Telephony, INTERACTION_STATES.Connected);
          } else {
            // If it is an outbound call and the previous set interaction state is initiated, clear the dialpad input value.
            if (call.direction === 'Outgoing' && this.state.currentCallState === INTERACTION_STATES.Initiated) {
              this.handleCallControlStateChange(CallControlStateChangeEventEnum.OutboundCallConnected);
            } else {
              this.handleCallControlStateChange(CallControlStateChangeEventEnum.CallConnected);
            }
            if (this.props.transcriptionConfig?.enabled) {
              this.transcriptionHandler(call);
            }
            this.prepareAndSetInteraction(call, CHANNEL_TYPES.Telephony, INTERACTION_STATES.Connected);
          }
          break;
        case 'LocalHold':
          this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : Received a LocalHold state change event.`);
          this.handleCallControlStateChange(CallControlStateChangeEventEnum.HoldCallCompleted);
          this.prepareAndSetInteraction(call);
          break;
        case 'RemoteHold':
          // Tested multiple remote hold scenarios, but a remoteHold event is never received by our app from the SDK.
          // Based on the current Teams ACS Calling Documentation the remoteHold event is sent to the remote participants call client when the agent initiates a hold event.
          // Additionally, it seems that our teams application will not receive a remoteHold event unless the remote participant is also using the ACS Calling SDK to place the call on hold.
          this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : Received a RemoteHold state change event, but this state is not handled in the current implementation.`);
          break;
        case 'InLobby':
          this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : Received a InLobby state change event, but this state is not handled in the current implementation.`);
          break;
        case 'Disconnecting':
          this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : Received a Disconnecting state change event.`);
          if (this.props.transcriptionConfig?.enabled) {
            this.transcriptionHandler(call);
          }
          break;
        case 'Disconnected':
          this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : Received a Disconnected state change event.`);
          this.handleCallControlStateChange(CallControlStateChangeEventEnum.EndCallCompleted);
          this.prepareAndSetInteraction(call, CHANNEL_TYPES.Telephony, INTERACTION_STATES.Disconnected);
          break;
        case 'EarlyMedia':
          this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : Received a EarlyMedia state change event, but this state is not handled in the current implementation.`);
          break;
        default:
          this.props.logger.log(LOG_LEVEL.Debug, functionName, `CallComponent : Teams Call Agent is in an unknown state. Refresh the page to end any current calls and restore functionality.`);
          break;
      }
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR + ${error}`);
    }
  }

  /**
   * Handles the UI changes required when Call Control state changes are made.
   * @param {CallControlStateChangeEventEnum} CallControlStateChangeEvent - The event that triggered the state change.
   * @memberof CallComponent
   */
  @bind
    handleCallControlStateChange(CallControlStateChangeEvent: CallControlStateChangeEventEnum, incomingCallList?: any, callList?: any, currentCallId?: string, dialpadInputValue?: string) {
    const functionName = 'handleCallControlStateChange';

    try {
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START - CallControlStateChangeEvent Received: ${CallControlStateChangeEvent}`);

      switch (CallControlStateChangeEvent) {
        case CallControlStateChangeEventEnum.IncomingCallInitiated:
          this.setState({ currentCallId: currentCallId, incomingCallList: incomingCallList, currentCallState: INTERACTION_STATES.Alerting, incomingCallAlert: true});
          break;
        case CallControlStateChangeEventEnum.IncomingCallEnded:
          this.setState({isOnCall: false, incomingCallAlert: false, currentCallState: INTERACTION_STATES.Disconnected, callControlButtonStates: { ...this.state.callControlButtonStates, acceptCallButtonDisabled: false, rejectCallButtonDisabled: false}});
          this.cleanupAfterCallEnd();
          break;
        case CallControlStateChangeEventEnum.AcceptCallInitiated:
          this.setState({callControlButtonStates: { ...this.state.callControlButtonStates, acceptCallButtonDisabled: true, rejectCallButtonDisabled: true}});
          break;
        case CallControlStateChangeEventEnum.AcceptCallCompleted:
          this.setState({ callList: callList, callControlButtonStates: { ...this.state.callControlButtonStates, acceptCallButtonDisabled: false}});
          break;
        case CallControlStateChangeEventEnum.AcceptCallError:
          this.setState({callControlButtonStates: { ...this.state.callControlButtonStates, acceptCallButtonDisabled: false, rejectCallButtonDisabled: false}, isOnCall: false, incomingCallAlert: false, currentCallState: INTERACTION_STATES.Disconnected, isCallOnHold: false, audioButtonChecked: true});
          this.cleanupAfterCallEnd();
          break;
        case CallControlStateChangeEventEnum.CallConnected:
          this.setState({ isOnCall: true , incomingCallAlert: false , currentCallState: INTERACTION_STATES.Connected });
          break;  
        case CallControlStateChangeEventEnum.RejectCallInitiated:
          this.setState({callControlButtonStates: { ...this.state.callControlButtonStates, acceptCallButtonDisabled: true, rejectCallButtonDisabled: true}});
          break;
        case CallControlStateChangeEventEnum.RejectCallError:
          this.setState({callControlButtonStates: { ...this.state.callControlButtonStates, acceptCallButtonDisabled: false, rejectCallButtonDisabled: false}, isOnCall: false, incomingCallAlert: false, currentCallState: INTERACTION_STATES.Disconnected, isCallOnHold: false, audioButtonChecked: true});
          this.cleanupAfterCallEnd();
          break;
        case CallControlStateChangeEventEnum.EndCallInitiated:
          this.setState({callControlButtonStates: { ...this.state.callControlButtonStates, endCallButtonDisabled: true}});
          break;
        case CallControlStateChangeEventEnum.EndCallCompleted:
          this.setState({isOnCall: false, incomingCallAlert: false, currentCallState: INTERACTION_STATES.Disconnected, isCallOnHold: false, audioButtonChecked: true});
          this.setState({callControlButtonStates: { ...this.state.callControlButtonStates, acceptCallButtonDisabled: false, rejectCallButtonDisabled: false, endCallButtonDisabled: false, muteCallButtonDisabled: false, holdCallButtonDisabled: false, resumeCallButtonDisabled: false, blindTransferButtonDisabled: false}});
          break;
        case CallControlStateChangeEventEnum.EndCallError:
          this.setState({isOnCall: false, incomingCallAlert: false, currentCallState: INTERACTION_STATES.Disconnected, isCallOnHold: false, audioButtonChecked: true});
          this.setState({callControlButtonStates: { ...this.state.callControlButtonStates, acceptCallButtonDisabled: false, rejectCallButtonDisabled: false, endCallButtonDisabled: false, muteCallButtonDisabled: false, holdCallButtonDisabled: false, resumeCallButtonDisabled: false, blindTransferButtonDisabled: false}});
          break;
        case CallControlStateChangeEventEnum.HoldCallInitiated:
          this.setState({callControlButtonStates: { ...this.state.callControlButtonStates, holdCallButtonDisabled: true}});
          break;
        case CallControlStateChangeEventEnum.HoldCallCompleted:
          this.setState({currentCallState: INTERACTION_STATES.OnHold, isCallOnHold: true , callControlButtonStates: { ...this.state.callControlButtonStates, holdCallButtonDisabled: false, }});
          break;
        case CallControlStateChangeEventEnum.HoldCallError:
          this.setState({callControlButtonStates: { ...this.state.callControlButtonStates, holdCallButtonDisabled: false}});
          break;
        case CallControlStateChangeEventEnum.ResumeCallInitiated:
          this.setState({callControlButtonStates: { ...this.state.callControlButtonStates, resumeCallButtonDisabled: true}});
          break;
        case CallControlStateChangeEventEnum.ResumeCallCompleted:
          this.setState({currentCallState: INTERACTION_STATES.Connected, isCallOnHold: false, callControlButtonStates: { ...this.state.callControlButtonStates, resumeCallButtonDisabled: false }});
          break;
        case CallControlStateChangeEventEnum.ResumeCallError:
          this.setState({callControlButtonStates: { ...this.state.callControlButtonStates, resumeCallButtonDisabled: false}});
          break;
        case CallControlStateChangeEventEnum.ToggleMuteError:
          this.setState({ audioButtonChecked: !this.state.audioButtonChecked });
          break;
        case CallControlStateChangeEventEnum.StartOutboundCallInitiated:
          this.setState({dialpadInputValue: dialpadInputValue, callList: callList, currentCallId: currentCallId});
          break;  
        case CallControlStateChangeEventEnum.StartOutboundCallCompleted:
          this.setState({ currentCallState: INTERACTION_STATES.Initiated, isOnCall: true});
          break;
        case CallControlStateChangeEventEnum.OutboundCallConnected:
          this.setState({ dialpadInputValue: '', isOnCall: true, incomingCallAlert: false , currentCallState: INTERACTION_STATES.Connected });
          break;
        default:
          break;
      }
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
    }
  }

  // TODO: Create a teams interaction object instead of using any for params
  /**
   * Before an interaction is send to the DaVinci Framework, the interaction needs to be formulated with
   * the proper call data. If there is any CAD, the CAD will be added to the details of the interaction.
   *
   * @memberof CallComponent
   */
  @bind
  async prepareAndSetInteraction(params: any, channel = CHANNEL_TYPES.Telephony,  state?: INTERACTION_STATES, CAD?: any): Promise<void> {
    const functionName = 'prepareAndSetInteraction';

    try {
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START`);

      let direction: INTERACTION_DIRECTION_TYPES;
      const details = new RecordItem('', '', '');
      let userDetails: IUserDetails;
      let newInteraction: IInteraction;

      if ((params?.callerInfo?.identifier?.kind === 'microsoftTeamsUser')
          || (this.isPhoneNumber(params?.callObject?.phoneNumber) === false)
      ) {
        direction = INTERACTION_DIRECTION_TYPES.Internal;
      } else {
        direction = params.direction ? TEAMS_DIRECTION_MAP[params.direction as keyof typeof TEAMS_DIRECTION_MAP] : INTERACTION_DIRECTION_TYPES.Inbound;
      }

      let callInfo;
      if (direction === INTERACTION_DIRECTION_TYPES.Inbound) {
        callInfo = {
          interaction_id: params.id,
          scenario_id: params.id,
          callType: params.callerInfo.identifier?.kind,
          phoneNumber: params.callerInfo.identifier.phoneNumber,
          CAD: params.callerInfo,
        };
      } else if (direction === INTERACTION_DIRECTION_TYPES.Outbound) {
        callInfo = {
          interaction_id: params.callObject.id,
          scenario_id: params.callObject.id,
          callType: params.callObject.kind,
          phoneNumber: params.callObject.phoneNumber,
          CAD: params.callObject,
        };
      } else {
        callInfo = {
          interaction_id: params.id,
          scenario_id: params.id,
          callType: params?.callerInfo?.identifier?.kind ?? params?.callObject?.kind,
          phoneNumber: params?.callerInfo?.identifier?.microsoftTeamsUserId ? await getDisplayName(this.props.authProvider, params.callerInfo.identifier.microsoftTeamsUserId) : await getDisplayName(this.props.authProvider, params.callObject.phoneNumber),
          CAD: {}
        };
      }

      const cad = Object.assign({}, callInfo?.CAD);
      const cadObject = this.constructCADObject(cad);
      for (const callData in cadObject) {
        details.setField(callData, '', '', cadObject[callData]);
      }

      if (channel === CHANNEL_TYPES.Telephony && (callInfo?.callType === 'phoneNumber' || callInfo?.callType === 'microsoftTeamsUser')) {
        details.setPhone('', '', callInfo?.phoneNumber);
        if (this.agentPhoneNumber) {
          details.setDialedPhone('', '', this.agentPhoneNumber);
        }
      }

      if (CAD !== undefined && this.props.transcriptionConfig?.enabled === true) {
        if (CAD.relation === 'END_USER') {
            let call = params as CallCommon;
            userDetails = {
              email: params.email !== undefined ? params.email : '',
              username: call.callerInfo.displayName !== undefined ? params.username : '',
              firstName: params.disp !== undefined ? params.firstName : '',
              lastName: params.lastName !== undefined ? params.lastName : '',
              attributes: params.attributes !== undefined ? params.attributes : [],
              profiles: [],
              userType: USER_TYPES.Client,
            }
        } else {
          userDetails = this.props.agentDetails;
          userDetails.userType = USER_TYPES.Agent;
        }

        const date = new Date();
        const currentTranscript: ITranscript = {
          id: date.getTime().toString(),
          data: CAD?.transcript,
          isComplete: false,
          context: userDetails,
          timestamp: date,
        };

        await this.storageService.addTranscript(callInfo?.interaction_id, currentTranscript);

        newInteraction = {
          interactionId: callInfo?.interaction_id,
          scenarioId: callInfo?.scenario_id,
          state: state,
          channelType: channel,
          direction: direction,
          details: details,
          transcripts: currentTranscript
        };
      } else {
        if (state === INTERACTION_STATES.Disconnected && this.props.transcriptionConfig.enabled && this.storageService.transcriptList[callInfo?.interaction_id] !== undefined && this.storageService.transcriptList[callInfo?.interaction_id].length > 0) {
          let completedTranscript: ICompletedTranscript = await this.storageService.removeAllTranscripts(callInfo?.interaction_id);
          newInteraction = {
            interactionId: callInfo?.interaction_id,
            scenarioId: callInfo?.scenario_id,
            state: this.state.currentCallState,
            channelType: channel,
            direction: direction,
            details: details,
            completedTranscript: completedTranscript 
          };
        } else {
          newInteraction = {
            interactionId: callInfo?.interaction_id,
            scenarioId: callInfo?.scenario_id,
            state: this.state.currentCallState,
            channelType: channel,
            direction: direction,
            details: details,
          };
        }
      }

      this.props.logger.log(LOG_LEVEL.Debug, functionName, `CallComponent : Interaction Created : `, newInteraction);

      try {
        setInteraction(newInteraction);
      } catch (error) {
        this.props.logger.log(LOG_LEVEL.Critical, functionName, `setInteraction Error`, error);
      }

      if (this.state.currentCallState === INTERACTION_STATES.Disconnected) {
        this.storageService.removeInteraction(newInteraction.scenarioId);
        this.cleanupAfterCallEnd();
      } else {
        this.storageService.addInteraction(newInteraction);
      }
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
    }
  }

  /**
   * Check to see if input value is a phone number
   * @memberof CallComponent
   */
  isPhoneNumber(microsoftCallIdentifier: string) {
    const functionName = 'isPhoneNumber';

    try{
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START`);

      if (microsoftCallIdentifier?.match(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im) === null) {
        this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END : Value not a phone number : ${microsoftCallIdentifier}`);
        return false
      }

      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END : Value is a phone number`);
      return true;
    } catch(error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
    }
  }

  /**
   * Retrieve user input from the dialpad
   * @memberof CallComponent
   */
  onChange(input: string) {
    const functionName = 'onChange';

    try{
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START`);

      this.setState({dialpadInputValue: input});

      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
    } catch(error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
    }
  }

    /**
   * Handles DTMF event from the dialpad
   * @param {DtmfTone} dtmfTone - The dtmfTone to be sent
   * @memberof CallComponent
   */
  onSendDtmfTone(dtmfTone: DtmfTone): Promise<void> {
    const functionName = 'onSendDtmfTone';

    try {
      this.handleDTMF(dtmfTone);
      return Promise.resolve();
    } catch(error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
      return Promise.reject();
    }
  }

  /**
   * Handles the show dialpad btn
   * @param {boolean} displayDialPad - Determines whether the DialPad should be displayed
   * @memberof CallComponent
   */
  @bind
  handleDial(displayDialPad: boolean) {
    const functionName = 'handleDial';

    try{
      this.setState({showDialPad: displayDialPad });
    } catch(error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
    }
  }
  
  /**
   * Handles caption active events sent from Teams SDK when Teams Closed captioning feature is enabled.
   * @memberof CallComponent
   */
  @bind
  async captionsActiveChangedHandler () {
    const functionName = 'captionsActiveChangedHandler';

    try {
      this.props.logger.log(LOG_LEVEL.Debug, functionName, 'Captions active changed handler.');
      
      let call: Call | TeamsCall = await this.state.callList[this.state.currentCallId];
      let currentCallCaptions: TeamsCaptions = this.callCaptionsList[this.state.currentCallId];

      if (currentCallCaptions.isCaptionsFeatureActive === true && call?.state !== 'Disconnected') {
        this.props.logger.log(LOG_LEVEL.Debug, functionName, 'Teams Captions are now active.');
      } else if (currentCallCaptions.isCaptionsFeatureActive === false && (call?.state === 'Disconnected' || call?.state === 'Disconnecting')) {
        this.props.logger.log(LOG_LEVEL.Debug, functionName, 'Teams Captions have been stopped.');
        currentCallCaptions.off('CaptionsActiveChanged', this.captionsActiveChangedHandler);
        // TODO: Do not handle spoken language change events for now as this is a feature supported with Premium teams licenses only.
        // currentCallCaptions.off('SpokenLanguageChanged', this.spokenLanguageChangedHandler);
      }
    } catch (error) {
       this.props.logger.log(LOG_LEVEL.Error, functionName, 'Error handling captions active change.', error);
    }
  }

  /**
   * Handles spoken language change events received from Teams SDK when Teams Closed captioning feature is enabled.
   * TODO: If/when we want to support this premium teams license feature, implement logic in this method to handle the events from Teams SDK.
   * @memberof CallComponent
   */
  /*
  @bind
  async spokenLanguageChangedHandler () {
    const functionName = 'spokenLanguageChangedHandler';

    try {
      this.props.logger.log(LOG_LEVEL.Debug, functionName, 'Spoken language changed handler.');

      

    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, 'Error handling spoken language change.', error);
    }
  }
  */

  /**
   * Handles new caption events received from Teams SDK when the Teams Closed captioning feature is enabled/active.
   * @memberof CallComponent
   */
  @bind
  async newCaptionHandler(data: TeamsCaptionsInfo) {
    const functionName = 'teamsCapHandler';

    try {
      if (data.resultType === 'Final') {
        this.props.logger.log(LOG_LEVEL.Trace, functionName, `Received a caption event that is finalized: TEAMS TRANSCRIPT::: ${data.captionText} :: ${JSON.stringify(JSON.stringify(data.speaker))}`);
        let call: Call | TeamsCall = await this.state.callList[this.state.currentCallId];
        
        // Determine if caption is from agent or client.
        if (data.speaker.identifier.kind === 'phoneNumber') {
          this.sendTranscription(call, 'END_USER', data.captionText);
        } else {
          this.sendTranscription(call, 'AGENT', data.captionText);
        }
      }
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, 'Error handling teams caption.', error);
    }
  }

  /**
   * Handles the setup/cleanup required when enabling transcription for an active call.
   * @param {Call | TeamsCall} call - The call object for which transcription is being enabled.
   * @memberof CallComponent
   */
  async transcriptionHandler(call: Call | TeamsCall) {
    const functionName = 'transcriptionHandler';

    try {

      if (!this.props.transcriptionConfig.useAzureCognitiveServicesTranscription) {
        this.props.logger.log(LOG_LEVEL.Trace, functionName, 'Utilizing Teams Closed Captioning for transcription services.');
        if (call.kind === CallKind.TeamsCall) {
          let currentCallCaptionList = this.callCaptionsList;
          currentCallCaptionList[this.state.currentCallId] = (currentCallCaptionList[this.state.currentCallId]) ? currentCallCaptionList[this.state.currentCallId] : (call as TeamsCall).feature(Features.Captions).captions as TeamsCaptions;
          if (call.state === 'Connected') {
            currentCallCaptionList[this.state.currentCallId].on('CaptionsActiveChanged', this.captionsActiveChangedHandler);
            // TODO: Subscribe for spoken language change events when implementing support for this premium teams license feature.
            // currentCallCaptionList[this.state.currentCallId].on('SpokenLanguageChanged', this.spokenLanguageChangedHandler);
            currentCallCaptionList[this.state.currentCallId].on('CaptionsReceived', this.newCaptionHandler);
            if (!currentCallCaptionList[this.state.currentCallId].isCaptionsFeatureActive) {
              // Must await otherwise failed to start captions error can be thrown by teams.
              await currentCallCaptionList[this.state.currentCallId].startCaptions();
            } else {
              this.props.logger.log(LOG_LEVEL.Debug, functionName, 'Teams Captions are already active for this call.');
            }
          } else if (call.state === 'Disconnected' || call.state === 'Disconnecting') {
            if (currentCallCaptionList[this.state.currentCallId].isCaptionsFeatureActive) {
              currentCallCaptionList[this.state.currentCallId].stopCaptions();
            } else {
              this.props.logger.log(LOG_LEVEL.Debug, functionName, 'Teams Captions have already been stopped for this call.');
            }
          }
        } else {
          this.props.logger.log(LOG_LEVEL.Trace, functionName, 'Captioning feature is only supported for Teams calls.');
        }
      } else {
        this.props.logger.log(LOG_LEVEL.Trace, functionName, 'Utilizing Azure Cognitive Services for real-time transcription.');
        // Make sure call is defined before creating event listener; should only happen once; only supports inbound calls
        if (call && call.direction === 'Incoming') {
          this.remoteAudioStarted = true;
          // Only Transcribe if configured; configured in Creators Studio; retrieved from an object in index.tsx
          // The remoteAudioStreamArray shows what was added/removed to remoteAudioStreams
          // Begin listening to the remoteAudioStreamsUpdated event
          call.on('remoteAudioStreamsUpdated', this.beginTranscription.bind(this));
        }
      }
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, 'Error initializing transcription.', error);
    }
  }

  @bind
  async beginTranscription() {
    const functionName = 'beginTranscription';

    try {
      let call: Call | TeamsCall = this.state.callList[this.state.currentCallId];

      // Configurations needed to utilize Azure Cognitive Services
      const speechConfig: SpeechConfig = SpeechConfig.fromSubscription(this.props.transcriptionConfig.speechKey, this.props.transcriptionConfig.speechRegion);

      // Grab the MediaStream object from the remoteAudioStreams array
      // remoteAudioStreams is part of the call object
      // It contains an array of remoteAudioStream objects
      // getMediaStream() gets the MediaStream associated with the RemoteAudioStream
      const mediaStream: MediaStream | undefined = await call?.remoteAudioStreams[0].getMediaStream(); // This only grabs one audio stream

      // Create AudioConfig based off MediaStream - clientAudio
      const clientAudioConfig: AudioConfig = AudioConfig.fromStreamInput(mediaStream as MediaStream);

      // Create AudioConfig based off Agent's microphone
      const agentAudioConfig: AudioConfig = AudioConfig.fromDefaultMicrophoneInput();

      // Create SpeechRecognizer based on the SpeechConfig and AudioConfig for the client
      const remoteSpeechRecognizer: SpeechRecognizer = new SpeechRecognizer(speechConfig, clientAudioConfig);

      // Create SpeechRecognizer based on the SpeechConfig and AudioConfig for the agent
      const agentSpeechRecognizer: SpeechRecognizer = new SpeechRecognizer(speechConfig, agentAudioConfig);

      // Begin listening to transcriptions from the remote
      remoteSpeechRecognizer.startContinuousRecognitionAsync(() => {
        const functionName = 'startContinuousRecognitionAsync';
        try {
          this.props.logger.log(LOG_LEVEL.Trace, functionName, 'Initializing remote transcription.');
          call?.on('remoteAudioStreamsUpdated', () => {
            const functionName = 'remoteAudioStreamsUpdated';
            try {
              if (call?.remoteAudioStreams.length === 0) {
                this.props.logger.log(LOG_LEVEL.Trace, functionName, 'Ending remote transcription');
                remoteSpeechRecognizer.stopContinuousRecognitionAsync();
              } else {
                this.props.logger.log(LOG_LEVEL.Loop, functionName, 'Remote audio updated');
              }
            } catch (error) {
              this.props.logger.log(LOG_LEVEL.Error, functionName, `Remote audio update error.`, error);
            }
          });
        } catch (error) {
          this.props.logger.log(LOG_LEVEL.Error, functionName, `Failed to initialize remote transcription.`, error);
        }
      });

      // Begin listening to transcriptions from the agent
      agentSpeechRecognizer.startContinuousRecognitionAsync(() => {
        const functionName = 'startContinuousRecognitionAsync';
          try {
            call?.on('remoteAudioStreamsUpdated', () => {
              const functionName = 'clientAudioStreamsUpdated';
              try {
                if (call?.remoteAudioStreams.length === 0) {
                  this.props.logger.log(LOG_LEVEL.Trace, functionName, 'Ending client transcription');
                  agentSpeechRecognizer.stopContinuousRecognitionAsync();
                } else {
                  this.props.logger.log(LOG_LEVEL.Loop, functionName, 'Client audio updated');
                }
              } catch (error) {
                this.props.logger.log(LOG_LEVEL.Error, functionName, `Client audio update error.`, error);
              }
            });
          } catch (error) {
            this.props.logger.log(LOG_LEVEL.Error, functionName, `Failed to initialize agent transcription.`, error);
          }
        }
      );

      // Constantly firing as words are detected
      remoteSpeechRecognizer.recognizing = (s, e) => {
        try {
          console.log('Remote: ' + e.result.text);
          console.log('Remote Offset in Ticks: ' + e.result.offset);
          console.log('Remote Duration in Ticks: ' + e.result.duration);
        } catch (error) {
          console.log('Failed to get Remote Audio ');
        }
      };

      // Only triggered when sentences are completed (pause in audio) or based on a configured time.
      // Default configured time is 15 seconds.
      remoteSpeechRecognizer.recognized = async (s, e) => {
        try {
          if (e.result.text !== undefined && e.result.text !== '') {
            let text: string = e.result.text;
            // const clientText: string[] = [text];
            this.sendTranscription(call, 'END_USER', text);
          }
          console.log('Client - RECOGNIZED:  ' + e.result.text);
        } catch (error) {
          console.log('Failed to Recognize Remote Audio ');
        }
      };

      // Constantly firing as words are detected
      agentSpeechRecognizer.recognizing = (s, e) => {
        try {
          console.log('Client: ' + e.result.text);
          console.log('Client Offset in Ticks: ' + e.result.offset);
          console.log('Client Duration in Ticks: ' + e.result.duration);
        } catch (error) {
          console.log('Failed to get Client Audio ');
        }
      };

      // Only triggered when sentences are completed (pause in audio) or based on a configured time.
      // Default configured time is 15 seconds.
      agentSpeechRecognizer.recognized = (s, e) => {
        try {

          if (e.result.text !== undefined && e.result.text !== '') {
            this.sendTranscription(call, 'HUMAN_AGENT', e.result.text);
          }
          console.log('Agent - RECOGNIZED:  ' + e.result.text);
        } catch (error) {
          console.log('Failed to Recognize Agent Audio ');
        }
      };
        // Stop listening to remoteAudioStreamsUpdated event
        call?.off('remoteAudioStreamsUpdated', this.beginTranscription);
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR ${error}`);
    }
  }

  /**
   * Handles building the transcript object once a finalized transcript event is received.
   * @param {Call | TeamsCall} call - The call object for which the transcript is being sent.
   * @param {string} relation - The relation of the transcript to the agent or client.
   * @param {String} transcript - The finalized transcript text.
   * @memberof CallComponent
   */
  @bind
  private async sendTranscription(call: Call | TeamsCall, relation: string, transcript: String) {
    const functionName = 'sendTranscription';

    try {
      const CAD: object = {
        relation,
        transcript,
      };

      let callInteractionState = this.teamsStateToInteractionState(call.state);
      this.prepareAndSetInteraction(call, CHANNEL_TYPES.Telephony, callInteractionState, CAD);
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
    }
  };

  /**
   * Handles all cleanup that is required after a call has ended.
   * @memberof CallComponent
   */
  cleanupAfterCallEnd() {
    const functionName = 'cleanupAfterCallEnd';

    try {
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START`);

      // Clean up incoming call listener.
      if (this.state.incomingCallList[this.state.currentCallId] !== undefined) {
        this.state.incomingCallList[this.state.currentCallId].off('callEnded', this.handleActiveCallStateChanges);
        let currentIncomingCallList = this.state.incomingCallList;
        delete currentIncomingCallList[this.state.currentCallId];
        this.setState({incomingCallList: currentIncomingCallList});
      }

      // Clean up remoteAudioStreamsUpdated event listener
      if (this.props.transcriptionConfig?.enabled && this.props.transcriptionConfig?.useAzureCognitiveServicesTranscription === true && this.state.callList[this.state.currentCallId]) {
        this.state.callList[this.state.currentCallId].off('remoteAudioStreamsUpdated', this.beginTranscription);
      }

      // Clean up caption event listeners
      if (this.props.transcriptionConfig?.enabled && this.props.transcriptionConfig?.useAzureCognitiveServicesTranscription === false &&  this.callCaptionsList[this.state.currentCallId] !== undefined) {
        this.callCaptionsList[this.state.currentCallId].off('CaptionsActiveChanged', this.captionsActiveChangedHandler);
        // TODO: Uncomment to subscribe from SpokenLanguageChanged event when we are implementing support this premium teams license feature.
        // this.callCaptionsList[this.state.currentCallId].off('SpokenLanguageChanged', this.spokenLanguageChangedHandler);
        delete this.callCaptionsList[this.state.currentCallId];
      }

      // Clean up stateChanged event listeners for the active call.
      if (this.state.callList[this.state.currentCallId] !== undefined) {
        this.state.callList[this.state.currentCallId].off('stateChanged', this.handleActiveCallStateChanges);
        let currentCallList = this.state.callList;
        delete currentCallList[this.state.currentCallId];
        this.setState({callList: currentCallList});
      }

      // Clean up transferRequested event listener
      if (this.blindTransferInitiated === true && this.transferAgent !== undefined) {
        this.transferAgent.off('stateChanged', () => {});
      }
      this.blindTransferInitiated = false;
      this.transferAgent = undefined;
      
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
    }
  }

  /**
   * React lifecycle method that is called when the component is unmmounted.
   * @memberof CallComponent
   */
  componentWillUnmount() {
    const functionName = 'componentWillUnmount';

    try {
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START`);
    
      window.removeEventListener('beforeunload', this.handleBeforeUnload);
      this.cleanupOnApplicationEnd();
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
    }
  }
  
  /**
   * Callback method that is used to run the cleanup method for when the page is refreshed, reloaded, or closed.
   * @memberof CallComponent
   */
  handleBeforeUnload = (event: BeforeUnloadEvent) => {
    const functionName = 'handleBeforeUnload';

    try {
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START`);

      window.removeEventListener('beforeunload', this.handleBeforeUnload);
      this.cleanupOnApplicationEnd();
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
    }   
  }

  /**
   * Handles all cleanup that is required when the application is being closed or reloaded. This includes scenarios where the user refreshes the page, closes the browser, etc.
   * @memberof CallComponent
   */
  cleanupOnApplicationEnd() {
    const functionName = 'cleanupOnApplicationEnd';
    
    try {
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : START`);

      // Cleans up expired calls and interactions from the storage service and sends them to the DaVinci Framework in the disconnected state.
      this.storageService.syncWithLocalStorage();
      const keys = Object.keys(this.storageService.interactionList);
      if (keys.length > 0) {
        for (const key of keys) {
          const interaction: IInteraction = this.storageService.interactionList[key];
          interaction.state = INTERACTION_STATES.Disconnected;
          if (this.storageService.transcriptList[interaction.interactionId] !== undefined && this.storageService.transcriptList[interaction.interactionId].length > 0) {
            interaction.completedTranscript = this.storageService.removeAllTranscripts(interaction.interactionId);
          }
          setInteraction(interaction);
          this.storageService.removeInteraction(interaction.scenarioId);
        }
      }

      // Clean up incoming call handler for a page refresh scenario.
      if (this.props.callAgent) {
        this.props.callAgent.off('incomingCall', this.incomingCallHandler);
      }

      // Clean up remoteAudioStreamsUpdated event listeners
      if (this.props.transcriptionConfig?.enabled && this.props.transcriptionConfig?.useAzureCognitiveServicesTranscription === true) {
        Object.values(this.state.callList).forEach((call: Call | TeamsCall) => {
          call.off('remoteAudioStreamsUpdated', this.beginTranscription);
        });
      }

      // Clean up captions event listeners
      if (this.props.transcriptionConfig?.enabled && this.props.transcriptionConfig?.useAzureCognitiveServicesTranscription === false &&  this.callCaptionsList !== undefined) {
        Object.values(this.callCaptionsList).forEach((captions: TeamsCaptions) => {
          captions.off('CaptionsActiveChanged', this.captionsActiveChangedHandler);
          // TODO: Uncomment to subscribe from SpokenLanguageChanged event when we are implementing support this premium teams license feature.
          // captions.off('SpokenLanguageChanged', this.spokenLanguageChangedHandler);
        });
      }
    
      // Clean up stateChanged event listeners for every call in page refresh scenario.
      Object.values(this.state.callList).forEach((call: Call | TeamsCall) => {
        call.off('stateChanged', this.handleActiveCallStateChanges);
      });
  
      // Clean up transferRequested event listener
      if (this.blindTransferInitiated === true && this.transferAgent !== undefined) {
        this.transferAgent.off('stateChanged', () => {});
      }
      this.blindTransferInitiated = false;
      this.transferAgent = undefined;
    
      this.props.logger.log(LOG_LEVEL.Trace, functionName, `CallComponent : END`);
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR +  ${error}`);
    }
  }

  /**
   * Before an interaction is send to the DaVinci Framework, the interaction needs to be formulated with
   * the proper call data. If there is any CAD, the CAD will be added to the details of the interaction.
   *
   * @memberof CallComponent
   */
  teamsStateToInteractionState(state: string): INTERACTION_STATES {
    switch (state) {
      case 'Alerting':
        return INTERACTION_STATES.Alerting;
      case 'Connected':
        return INTERACTION_STATES.Connected;
      case 'Disconnected':
        return INTERACTION_STATES.Disconnected;
      case 'Hold':
        return INTERACTION_STATES.OnHold;
      default:
        return INTERACTION_STATES.Connected;
    }
  }

  @bind
  render() {
    const functionName = 'render';
    try {
      return (
          // #Home-CallComponent
          <div id={this.props.id}>
              {!this.state.isOnCall ? (
              <FluentThemeProvider>
                  {this.state.incomingCallAlert && (
                    <div style={{display: 'flex'}}>
                      <div className='incomingCallToast'>
                        <IncomingCallToast
                            callerName={this.displayName}
                            alertText={'incoming Call'}
                            onClickAccept={this.acceptCall}
                            onClickReject={this.rejectCall}
                        />
                      </div>
                    </div>
                  )}
              </FluentThemeProvider>
            )  :
              <><div className='layer' id='greyLayer' style={{ position: 'absolute', top: '0px', left: '0px', zIndex: '2', background: 'white', opacity: '0.7', width: '100%', height: '100%', display: 'none' }}></div><div>
              <VideoTile
                styles={this.videoTileStyles}
                displayName={this.displayName}
                renderElement={null}
                isMirrored={true}
                personaMinSize={72} />
            </div></>
                  }
                  {this.state.isOnCall && <ControlBar styles={this.controlBarStyles}>
                      {this.props.muteEnabled === true && <MicrophoneButton
                          checked={this.state.audioButtonChecked}
                          onClick={() => {
                              this.setState({ audioButtonChecked: !this.state.audioButtonChecked });
                              this.state.callList[this.state.currentCallId].isMuted ? this.unmuteCall() : this.muteCall();
                          }}
                      />}
                      {!this.state.isCallOnHold && <ControlBarButton
                          key={'openDTMF'}
                          onClick={this.openDTMF}
                          onRenderIcon={() => <Dialpad20Filled key={'dtmfIconKey'} primaryFill="currentColor"/>}
                        />}
                      {this.state.isCallOnHold && <ControlBarButton
                        disabled={this.state.callControlButtonStates.blindTransferButtonDisabled}
                        key={'initiateBlindTransfer'}
                        onClick={this.blindTransfer}    
                        onRenderIcon={() => <CallTransfer20Filled key={'transferIconKey'} primaryFill="currentColor"/>}
                      />}
                      {!this.state.isCallOnHold && <ControlBarButton
                          disabled={this.state.callControlButtonStates.holdCallButtonDisabled}
                          key={'holdCall'}
                          onClick={this.holdCall}
                          onRenderIcon={() => <Pause20Filled key={'holdIconKey'} primaryFill="currentColor"/>}
                        />}
                      {this.state.isCallOnHold && <ControlBarButton
                        disabled={this.state.callControlButtonStates.resumeCallButtonDisabled}
                        key={'resumeCall'}
                        onClick={this.resumeCall}
                        onRenderIcon={() => <Pause20Regular key={'resumeIconKey'} primaryFill="currentColor" />}
                      />}
                      <EndCallButton
                          disabled={this.state.callControlButtonStates.endCallButtonDisabled}
                          onClick={this.endCall} 
                      />
                  </ControlBar>
                }
          </div>
      );
    } catch (error) {
      this.props.logger.log(LOG_LEVEL.Error, functionName, `CallComponent : ERROR`, error);
    }
  }
}
export default CallComponent;
