import React from 'react';
import { DTMFdigits } from './Models/DTMFevents.enum';
import { LOG_LEVEL, CHANNEL_TYPES, CONTEXTUAL_OPERATION_TYPE, SearchRecords, registerClickToAct } from '@amc-technology/davinci-api';

interface props {
    logger: any;
    acceptCall: any;
    blindTransfer: any;
    rejectCall: any;
    hangupCall: any;
    holdCall: any;
    resumeCall: any;
    setTeamsPresence: any;
    muteCall: any;
    unmuteCall: any;
    handleDTMF: any;
}

const DTMF_MAP: {[key: string]: DTMFdigits} = {
    '0': DTMFdigits.ZERO,
    '1': DTMFdigits.ONE,
    '2': DTMFdigits.TWO,
    '3': DTMFdigits.THREE,
    '4': DTMFdigits.FOUR,
    '5': DTMFdigits.FIVE,
    '6': DTMFdigits.SIX,
    '7': DTMFdigits.SEVEN,
    '8': DTMFdigits.EIGHT,
    '9': DTMFdigits.NINE,
    '*': DTMFdigits.STAR,
    '#': DTMFdigits.POUND,
  };

export class ClickToActComponent extends React.Component<props> {
    logger: any;
    acceptCall: any;
    blindTransfer: any;
    rejectCall: any;
    hangupCall: any;
    holdCall: any;
    resumeCall: any;
    setTeamsPresence: any;
    muteCall: any;
    unmuteCall: any;
    handleDTMF: any;

    private readonly className = 'TeamsClickToAct';
    constructor(props:any) {
        super(props);
        this.logger = props.logger;
        this.acceptCall = props.acceptCall;
        this.blindTransfer = props.blindTransfer;
        this.rejectCall = props.rejectCall;
        this.hangupCall = props.hangupCall;
        this.holdCall = props.holdCall;
        this.resumeCall = props.resumeCall;
        this.muteCall = props.muteCall;
        this.unmuteCall = props.unmuteCall;
        this.setTeamsPresence = props.setTeamsPresence;
        this.handleDTMF = props.handleDTMF;

    }

       /**
   * Register for Click to Act events
   * 
   * @memberof ClickToActComponent
   */
    public initApi(): void {
        const functionName = `initApi`;
        try {
            this.logger.log(LOG_LEVEL.Trace, functionName, `ClickToActComponenet : START`);
            registerClickToAct(this.handleClickToAct.bind(this));
        } catch (error) {
            this.logger.log(LOG_LEVEL.Error,functionName, `ClickToActComponenet : ERROR`, error);
        }
    }
    
    /**
   * Handle events coming from click to act
   *
   * @param {string} phoneNumber - phone number from click to act
   * @param {SearchRecords} records - records from click to act
   * @param {CHANNEL_TYPES} channelType - Channel type from click to act
   * @param {number} action - action to perform event from click to act
   * @param {string} interactionID - intercation ID from click to act
   * @param {object} data - data from click to act
   * @memberof ClickToActComponent
   */
    async handleClickToAct( phoneNumber: string, records?: SearchRecords, channelType?: CHANNEL_TYPES,
                            action?: number, interactionID?: string, data?: any ): Promise<void> {
        const functionName = `handleClickToAct`;
        try {
            this.logger.log(LOG_LEVEL.Trace, functionName, `ClickToActComponenet : START`);
            if (action !== null) {
                switch (action) {
                case CONTEXTUAL_OPERATION_TYPE.Answer:
                    this.answerCallCTA();
                    break;
                case CONTEXTUAL_OPERATION_TYPE.Reject:
                    this.rejectCallCTA();
                    break;
                case CONTEXTUAL_OPERATION_TYPE.Hangup:
                    this.disconnectCallCTA();
                    break;
                case CONTEXTUAL_OPERATION_TYPE.Hold:
                    this.holdCallCTA();   
                    break;
                case CONTEXTUAL_OPERATION_TYPE.Unhold:
                    this.resumeCallCTA();
                    break;
                case CONTEXTUAL_OPERATION_TYPE.Mute:
                    this.muteCallCTA();
                    break;
                case CONTEXTUAL_OPERATION_TYPE.Unmute:
                    this.unmuteCallCTA();
                    break;
                case CONTEXTUAL_OPERATION_TYPE.SetPresence:
                    if(data){
                        this.setPresenceToTeamsCTA(data);
                    }
                    break;
                case CONTEXTUAL_OPERATION_TYPE.DTMF:
                    if(data) {
                        this.handleDTMFCTA(data);
                    }
                    break;
                case CONTEXTUAL_OPERATION_TYPE.BlindTransfer:
                    this.blindTransferCTA();
                    break;
                }
            }
        }  catch (error) {
            this.logger.log(LOG_LEVEL.Error,functionName, `ClickToActComponenet : ERROR`, error);
            }
    }

    /**
   * Accept the call - event coming from Click to Act
   *
   * @memberof ClickToActComponent
   */
    private async answerCallCTA() {
        const functionName = `answerCallCTA`;
        try {
            this.logger.log(LOG_LEVEL.Trace, functionName, `ClickToActComponenet : START`);
            this.acceptCall();
        } catch (error) {
          this.logger.log(LOG_LEVEL.Error,functionName, `ClickToActComponenet : ERROR`, error);
        }
    }

     /**
   * Transfer Call to another teams agent - event coming from Click to Act
   *
   * @memberof ClickToActComponent
   */
     private async blindTransferCTA() {
        const functionName = `blindTransferCTA`;
        try {
            this.logger.log(LOG_LEVEL.Trace, functionName, `ClickToActComponenet : START`);
            this.blindTransfer();
        } catch (error) {
          this.logger.log(LOG_LEVEL.Error,functionName, `ClickToActComponenet : ERROR`, error);
        }
    }

    /**
   * Reject the call event - coming from Click to Act
   *
   * @memberof ClickToActComponent
   */
    private async rejectCallCTA() {
        const functionName = `rejectCallCTA`;
        try {
            this.logger.log(LOG_LEVEL.Trace, functionName, `ClickToActComponenet : START`);
            this.rejectCall();
        } catch (error) {
          this.logger.log(LOG_LEVEL.Error,functionName, `ClickToActComponenet : ERROR`, error);
        }
    }

     /**
   * Hangup the call - event coming from Click to Act
   *
   * @memberof ClickToActComponent
   */
    private async disconnectCallCTA() {
        const functionName = `disconnectCallCTA`;
        try {
            this.logger.log(LOG_LEVEL.Trace, functionName, `ClickToActComponenet : START`);
            this.hangupCall();
        } catch (error) {
          this.logger.log(LOG_LEVEL.Error,functionName, `ClickToActComponenet : ERROR`, error);
        }
    }

     /**
   * Holds the call - event coming from Click to Act
   *
   * @memberof ClickToActComponent
   */
    private async holdCallCTA() {
        const functionName = `holdCallCTA`;
        try {
            this.logger.log(LOG_LEVEL.Trace, functionName, `ClickToActComponenet : START`);
            this.holdCall();
        } catch (error) {
          this.logger.log(LOG_LEVEL.Error,functionName, `ClickToActComponenet : ERROR`, error);
        }
    }

      /**
   * Resume the call - event coming from Click to Act
   *
   * @memberof ClickToActComponent
   */
    private async resumeCallCTA() {
        const functionName = `resumeCallCTA`;
        try {
            this.logger.log(LOG_LEVEL.Trace, functionName, `ClickToActComponenet : START`);
            this.resumeCall();
        } catch (error) {
          this.logger.log(LOG_LEVEL.Error,functionName, `ClickToActComponenet : ERROR`, error);
        }
    }

      /**
   * Mute the call - event coming from Click to Act
   *
   * @memberof ClickToActComponent
   */
    private async muteCallCTA() {
        const functionName = `muteCallCTA`;
        try {
            this.logger.log(LOG_LEVEL.Trace, functionName, `ClickToActComponenet : START`);
            this.muteCall()
        } catch (error) {
          this.logger.log(LOG_LEVEL.Error,functionName, `ClickToActComponenet : ERROR`, error);
        }
    }

      /**
   * Unmute the call - event coming from Click to Act
   *
   * @memberof ClickToActComponent
   */
    private async unmuteCallCTA() {
        const functionName = `unmuteCallCTA`;
        try {
            this.logger.log(LOG_LEVEL.Trace, functionName, `ClickToActComponenet : START`);
            this.unmuteCall()
        } catch (error) {
          this.logger.log(LOG_LEVEL.Error,functionName, `ClickToActComponenet : ERROR`, error);
        }
    }

    /**
   * Set presence to Teams - event coming from Click to Act
   * @param {any} data - presence to be set in Teams
   * @memberof ClickToActComponent
   */
    private async setPresenceToTeamsCTA(data: any) {
        const functionName = `setPresenceToTeamsCTA`;
        try {
            this.logger.log(LOG_LEVEL.Trace, functionName, `ClickToActComponenet : START`);
            this.setTeamsPresence(data);
        } catch (error) {
          this.logger.log(LOG_LEVEL.Error,functionName, `ClickToActComponenet : ERROR`, error);
        }
    }

       /**
   * Send DTMF tone - event coming from Click to Act
   * @param {string} digit - Digit to send dtmf tone
   * @memberof ClickToActComponent
   */
    private async handleDTMFCTA(digit: string) {
        const functionName = `handleDTMFCTA`;
        try {
            this.logger.log(LOG_LEVEL.Trace, functionName, `ClickToActComponenet : START`);
            this.handleDTMF(DTMF_MAP[digit]);
        } catch (error) {
          this.logger.log(LOG_LEVEL.Error,functionName, `ClickToActComponenet : ERROR`, error);
        }
    }
    
}

export default ClickToActComponent;
    